import React from 'react';
import { Provider } from 'react-redux';

function TransactionData({ transaction, onClose }) {
  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className='fixed inset-y-0 right-0 w-80 bg-white shadow-lg overflow-auto z-50 p-5'
      onClick={handleClickOutside}
    >
      <button onClick={onClose} className='float-right'>
        X
      </button>

      <h2 className='text-2xl font-bold mb-5 text-center'>
        Transaction Details
      </h2>
      {transaction && (
        <div className='overflow-x-auto'>
          <table className='w-full table-auto text-left whitespace-no-wrap'>
            <tbody>
              <tr className='bg-gray-100 hover:bg-gray-200'>
                <th className='px-4 py-2'>Provider Phone</th>
                <td className='px-4 py-2'>{transaction.providerPhone}</td>
              </tr>
              {transaction.orderType === 'BUY' ? (
                <tr className='hover:bg-gray-200'>
                  <th className='px-4 py-2'>Recipient Address</th>
                  <td className='px-4 py-2'>{transaction.recipientAddress}</td>
                </tr>
              ) : (
                <tr className='hover:bg-gray-200'>
                  <th className='px-4 py-2'>User UPI ID</th>
                  <td className='px-4 py-2'>{transaction.upiUsed}</td>
                </tr>
              )}

              <tr className='bg-gray-100 hover:bg-gray-200'>
                <th className='px-4 py-2'>Provider State</th>
                <td className='px-4 py-2'>{transaction.providerState}</td>
              </tr>
              <tr className='hover:bg-gray-200'>
                <th className='px-4 py-2'>Contract State</th>
                <td className='px-4 py-2'>{transaction.contractState}</td>
              </tr>
              <tr className='bg-gray-100 hover:bg-gray-200'>
                <th className='px-4 py-2'>Order Type</th>
                <td className='px-4 py-2'>{transaction.orderType}</td>
              </tr>
              <tr className='hover:bg-gray-200'>
                <th className='px-4 py-2'>Created At</th>
                <td className='px-4 py-2'>{transaction.createdAt}</td>
              </tr>
              <tr className='bg-gray-100 hover:bg-gray-200'>
                <th className='px-4 py-2'>Amount</th>
                <td className='px-4 py-2'>{transaction.amount}</td>
              </tr>
              <tr className='hover:bg-gray-200'>
                <th className='px-4 py-2'>INR Amount</th>
                <td className='px-4 py-2'>{transaction.inrAmount}</td>
              </tr>

              {transaction.providerTransaction ? (
                <>
                  <tr className='bg-gray-100 hover:bg-gray-200'>
                    <th className='px-4 py-2'>Verification Mode</th>
                    <td className='px-4 py-2'>
                      {transaction.ProviderTransaction.verificationMode}
                    </td>
                  </tr>

                  <tr className='hover:bg-gray-200'>
                    <th className='px-4 py-2'>Message</th>
                    <td className='px-4 py-2'>
                      {transaction.ProviderTransaction.log}
                    </td>
                  </tr>
                </>
              ) : (
                ''
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default TransactionData;
