import { useState, useEffect } from 'react';
import Header from '../components/Header';
import {
  useUpdateRpMutation,
  useTransferReferralRewardMutation,
  useGetVoterAddressQuery,
  useGetRecommenderAddressesQuery,
} from '../query-services/brokerApi';
import Cookies from 'js-cookie';

function ReputationSystem() {
  const brokerAddress = Cookies.get('brokerAddress');

  const [
    updateRp,
    { data: updateRpData, error: updateRpError, isLoading: updateRpIsLoading },
  ] = useUpdateRpMutation();
  const [
    sendRewards,
    {
      data: sendRewardsData,
      error: sendRewardsError,
      isLoading: sendRewardsIsLoading,
    },
  ] = useTransferReferralRewardMutation();
  const [userAddress, setUserAddress] = useState('');
  const [recommenderAddress, setRecommenderAddress] = useState('');
  const [skipQuery, setSkipQuery] = useState(true);
  const [skipRecommentaionsQuery, setSkipRecommentaionsQuery] = useState(true);
  const {
    data: voterAddressData,
    error: voterAddressError,
    isLoading: voterAddressIsLoading,
  } = useGetVoterAddressQuery(
    { brokerAddress, userAddress },
    { skip: skipQuery }
  );
  const {
    data: recommenderAddressData,
    error: recommenderAddressError,
    isLoading: recommenderAddressIsLoading,
  } = useGetRecommenderAddressesQuery(
    { brokerAddress, recommenderAddress },
    { skip: skipRecommentaionsQuery }
  );
  !recommenderAddressIsLoading &&
    recommenderAddressData &&
    console.log('recommenderAddressData', recommenderAddressData);
  const [inAppWalletAddress, setInAppWalletAddress] = useState('');
  const [reputationPointToUpdate, setReputationPointToUpdate] = useState('');
  const [fundsToSendForReward, setFundsToSendForReward] = useState('');
  const [submitPressed, setSubmitPressed] = useState(false);

  // let voterAddress = "";
  // if (!voterAddressIsLoading && voterAddressData) {
  //   voterAddress = voterAddressData.data;
  // }
  const fetchVoterAddress = () => {
    if (userAddress === '') {
      alert('Please enter a valid address');
      setSkipQuery(true);

      return;
    }
    if (userAddress.length > 0) {
      setSkipQuery(false);
    }
  };
  const fetchRecommenderAddress = () => {
    if (recommenderAddress === '') {
      alert('Please enter a valid address');
      setSkipRecommentaionsQuery(true);

      return;
    }
    if (recommenderAddress.length > 0) {
      setSkipRecommentaionsQuery(false);
    }
  };

  const updateReputationPoints = async () => {
    if (inAppWalletAddress == '') {
      alert('Please enter wallet address');
      return;
    }

    if (reputationPointToUpdate == 0) {
      alert('Please enter valid points to update');
      return;
    }

    await updateRp({
      brokerAddress,
      userAddress: inAppWalletAddress,
      rpChange: reputationPointToUpdate,
    });

    setInAppWalletAddress('');
    setReputationPointToUpdate(0);
  };

  const sendRewardFunds = async () => {
    if (fundsToSendForReward == 0) {
      alert('Please enter valid rewards to send');
      return;
    }
    await sendRewards({ brokerAddress, amount: fundsToSendForReward });

    setFundsToSendForReward(0);
  };
  console.log('voterAddressData.data', voterAddressData);
  return (
    <div className='w-4/5'>
      <div className='header w-full'>
        <Header />
      </div>
      <div className='top-container flex w-full justify-between items-center p-5'>
        <div className='flex items-end h-full w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <div className='inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-2  sm:w-full'>
            <div className='px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <div className='mt-2'>
                    <label className='block font-bold mt-2'>
                      Recommender address
                    </label>
                    <input
                      type='text'
                      placeholder='Wallet address'
                      value={userAddress}
                      onChange={(e) => setUserAddress(e.target.value)}
                      className='mt-1 w-full px-3 py-2 border border-gray-300 text-black rounded-md'
                    />
                    {!voterAddressIsLoading && voterAddressData ? (
                      <p>Voter address : {voterAddressData.data}</p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='px-4 py-3 sm:px-6 sm:flex justify-center w-full'>
              <span className='flex rounded-md shadow-sm sm:ml-3 w-1/2'>
                {voterAddressIsLoading ? (
                  <button
                    type='button'
                    className='justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-black shadow-sm hover:bg-[#01c38e] focus:outline-none focus:border-[#01c38e] focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex items-center'
                  >
                    <div className='animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900 mr-4'></div>
                    Processing...
                  </button>
                ) : (
                  <button
                    onClick={fetchVoterAddress}
                    type='button'
                    className='justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-black shadow-sm hover:bg-[#01c38e] focus:outline-none focus:border-[#01c38e] focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                  >
                    Submit
                  </button>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>{' '}
      <div className='top-container flex w-full justify-between items-center p-5'>
        <div className='flex items-end h-full w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <div className='inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-2  sm:w-full'>
            <div className='px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <div className='mt-2'>
                    <label className='block font-bold mt-2'>
                      Wallet Recommendations
                    </label>
                    <input
                      type='text'
                      placeholder='Wallet address'
                      value={recommenderAddress}
                      onChange={(e) => setRecommenderAddress(e.target.value)}
                      className='mt-1 w-full px-3 py-2 border border-gray-300 text-black rounded-md'
                    />
                    {!recommenderAddressIsLoading && recommenderAddressData ? (
                      <p>
                        Recommender address :{' '}
                        {recommenderAddressData.data.map((address, index) => (
                          <div>{address}</div>
                        ))}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='px-4 py-3 sm:px-6 sm:flex justify-center w-full'>
              <span className='flex rounded-md shadow-sm sm:ml-3 w-1/2'>
                {recommenderAddressIsLoading ? (
                  <button
                    type='button'
                    className='justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-black shadow-sm hover:bg-[#01c38e] focus:outline-none focus:border-[#01c38e] focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex items-center'
                  >
                    <div className='animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900 mr-4'></div>
                    Processing...
                  </button>
                ) : (
                  <button
                    onClick={fetchRecommenderAddress}
                    type='button'
                    className='justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-black shadow-sm hover:bg-[#01c38e] focus:outline-none focus:border-[#01c38e] focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                  >
                    Submit
                  </button>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='top-container flex w-full justify-between items-center p-5'>
        <div className='flex items-end h-full w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <div className='inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-2  sm:w-full'>
            <div className='px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <div className='mt-2'>
                    <label className='block font-bold mt-2'>
                      In app wallet
                    </label>

                    <input
                      type='text'
                      placeholder='Wallet address'
                      value={inAppWalletAddress}
                      onChange={(e) => setInAppWalletAddress(e.target.value)}
                      className='mt-1 w-full px-3 py-2 border border-gray-300 text-black rounded-md'
                    />
                    <label className='block font-bold mt-2'>
                      Reputation point
                    </label>

                    <input
                      type='number'
                      placeholder='Ex: 2 or -2'
                      value={reputationPointToUpdate}
                      onChange={(e) =>
                        setReputationPointToUpdate(e.target.value)
                      }
                      className='mt-1 w-full px-3 py-2 border border-gray-300 text-black rounded-md'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='px-4 py-3 sm:px-6 sm:flex justify-center w-full'>
              <span className='flex rounded-md shadow-sm sm:ml-3 w-1/2'>
                {updateRpIsLoading ? (
                  <button
                    type='button'
                    className='justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-black shadow-sm hover:bg-[#01c38e] focus:outline-none focus:border-[#01c38e] focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex items-center'
                  >
                    <div className='animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900 mr-4'></div>
                    Processing...
                  </button>
                ) : (
                  <button
                    onClick={updateReputationPoints}
                    type='button'
                    className='justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-black shadow-sm hover:bg-[#01c38e] focus:outline-none focus:border-[#01c38e] focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                  >
                    Submit
                  </button>
                )}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='top-container flex w-full justify-between items-center p-5'>
        <div className='flex items-end h-full w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <div className='inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-2  sm:w-full'>
            <div className='px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <div className='mt-2'>
                    <label className='block font-bold mt-2'>
                      Allocate funds for referal rewards
                    </label>

                    <input
                      type='number'
                      placeholder='Ex: 100'
                      value={fundsToSendForReward}
                      onChange={(e) => setFundsToSendForReward(e.target.value)}
                      className='mt-1 w-full px-3 py-2 border border-gray-300 text-black rounded-md'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='px-4 py-3 sm:px-6 sm:flex justify-center w-full'>
              <span className='flex rounded-md shadow-sm sm:ml-3 w-1/2'>
                {sendRewardsIsLoading ? (
                  <button
                    type='button'
                    className='justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-black shadow-sm hover:bg-[#01c38e] focus:outline-none focus:border-[#01c38e] focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex items-center'
                  >
                    <div className='animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900 mr-4'></div>
                    Processing...
                  </button>
                ) : (
                  <button
                    onClick={sendRewardFunds}
                    type='button'
                    className='justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-black shadow-sm hover:bg-[#01c38e] focus:outline-none focus:border-[#01c38e] focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                  >
                    Submit
                  </button>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReputationSystem;
