import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Transactions from './pages/Transactions';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import ProtectedRoutes from './utils/ProtectedRoutes';
import { useSelector } from 'react-redux';
import ManageProviders from './pages/ManageProviders';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import ArchivedTransactions from './pages/ArchivedTransactions';
import ReputationSystem from './pages/ReputationSystem';
import SuperAdmin from './pages/SuperAdmin';

function App() {
  return (
    <div className='App w-full flex'>
      <Router>
        <AppRoutes />
      </Router>
    </div>
  );
}

function AppRoutes() {
  const navigate = useNavigate();

  // const isAuth = useSelector((state) => state.uiState.userState.isAuth);
  // console.log(isAuth);
  const brokerType = Cookies.get('brokerType');

  const isAuth = Cookies.get('isAuth');
  // console.log(isAuth);
  const location = useLocation();

  // Check if the current route is the login page
  const isLoginPage = location.pathname === '/login';
  // console.log(isLoginPage);

  if (isAuth && location.pathname === '/') {
    navigate('/dashboard');
  }
  // const showSidebar = isAuth && !isLoginPage ;
  return (
    <>
      {isAuth && <Sidebar />}
      <Routes>
        <Route path='/login' element={<Login />} />

        {/* <Route path='/' element={<Login />} /> */}
        {/* <Route path='/' element={<Dashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/transactions' element={<Transactions />} /> */}

        <Route element={<ProtectedRoutes auth={isAuth} />}>
          {/* <Route path='/' element={<Dashboard />} /> */}
          <Route path='/' element={<Dashboard />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/transactions' element={<Transactions />} />
          <Route
            path='/archivedtransactions'
            element={<ArchivedTransactions />}
          />
          <Route path='/manageproviders' element={<ManageProviders />} />
          <Route path='/reputationsystem' element={<ReputationSystem />} />
          {/* <Route path='/superadmin' element={<SuperAdmin />} /> */}
        </Route>
        <Route
          element={<ProtectedRoutes auth={isAuth} allowedRoles={['ADMIN']} />}
        >
          {/* routes accessible only to superadmin */}
          <Route path='/superadmin' element={<SuperAdmin />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
