import { useState, useEffect } from 'react';
import Header from '../components/Header';
import {
  useUpdateRpMutation,
  useTransferReferralRewardMutation,
  usePriceHandlingMutation,
  useGetPriceDiscoveryQuery,
  useGetBinancePricesQuery,
} from '../query-services/brokerApi';
import MetricsComponent from '../components/Dashboard-components/MetricsComponent';
import Cookies from 'js-cookie';

function SuperAdmin() {
  const brokerAddress = Cookies.get('brokerAddress');

  const [priceHandling, { data, error, isLoading, isFetching, isSuccess }] =
    usePriceHandlingMutation();

  const [isAutomatedPricing, setIsAutomatedPricing] = useState(false);

  const {
    data: priceDiscoveryData,
    error: priceDiscoveryError,
    isLoading: priceDiscoveryDataIsLoading,
  } = useGetPriceDiscoveryQuery(brokerAddress);

  const {
    data: binancePriceData,
    error: binancePriceError,
    isLoading: binancePriceIsLoading,
  } = useGetBinancePricesQuery(brokerAddress);

  let binanceBuyPrice = 0;
  let binanceSellPrice = 0;
  if (!binancePriceIsLoading && binancePriceData) {
    binanceBuyPrice = binancePriceData?.data?.buyMedianPrice?.toFixed(2);
    binanceSellPrice = binancePriceData?.data?.sellMedianPrice?.toFixed(2);
  }

  const [buyPricing, setBuyPricing] = useState(null);
  const [sellPricing, setSellPricing] = useState(null);

  const toggleAutomatePricing = async () => {
    if (!priceDiscoveryDataIsLoading && priceDiscoveryData) {
      await priceHandling({
        brokerAddress,
        automated: !priceDiscoveryData?.data?.lastActiveSettings?.automated,
      });
    }
  };
  const updateBuyPricing = async () => {
    const finalRequestBuyData = {
      brokerAddress,
      buyChangePercent: buyPricing,
    };
    await priceHandling(finalRequestBuyData);
  };
  const updateSellPricing = async () => {
    console.log(sellPricing);
    const finalRequestBuyData = {
      brokerAddress,
      sellChangePercent: sellPricing,
    };
    await priceHandling(finalRequestBuyData);
  };
  return (
    <div className='w-4/5'>
      <div className='header w-full'>
        <Header />
      </div>
      <div className='w-full flex gap-4 justify-center p-4'>
        <MetricsComponent
          type={'Current Binance Buy Price'}
          amount={binanceBuyPrice}
          prefix={'INR'}
        />
        <MetricsComponent
          type={'Current Binance Sell Price'}
          amount={binanceSellPrice}
          prefix={'INR'}
        />
      </div>{' '}
      <div className='w-full flex gap-4 justify-center p-4'>
        <MetricsComponent
          type={'Current P2PX Buy Price'}
          amount={
            !priceDiscoveryDataIsLoading &&
            priceDiscoveryData?.data?.currentBuyPrice
          }
          prefix={'INR'}
        />
        <MetricsComponent
          type={'Current P2PX Sell Price'}
          amount={
            !priceDiscoveryDataIsLoading &&
            priceDiscoveryData?.data?.currentSellPrice
          }
          prefix={'INR'}
        />
      </div>{' '}
      <div className='w-full flex gap-4 justify-center p-4'>
        <MetricsComponent
          type={'Buy Price Change in %'}
          amount={
            !priceDiscoveryDataIsLoading &&
            priceDiscoveryData?.data?.lastActiveSettings?.buyChangePercent
              ? priceDiscoveryData?.data?.lastActiveSettings?.buyChangePercent
              : 'N/A'
          }
        />
        <MetricsComponent
          type={'Sell Price Change in %'}
          amount={
            !priceDiscoveryDataIsLoading &&
            priceDiscoveryData?.data?.lastActiveSettings?.sellChangePercent
              ? priceDiscoveryData?.data?.lastActiveSettings?.sellChangePercent
              : 'N/A'
          }
        />
      </div>
      <div className='sup-container w-full '>
        <div className='pricing-container w-full flex gap-4 justify-center p-4'>
          <div className='automated-container  p-4  gap-4 flex-col flex items-center w-1/3 rounded-md border-2 border-emerald-400 '>
            <div className='heading font-semibold uppercase text-lg'>
              Pricing
            </div>
            <div className='toggleSwitch flex items-center gap-2'>
              <div className='text text-lg'>Automate pricing </div>
              <label className='flex items-center cursor-pointer'>
                <div className='relative'>
                  <input
                    type='checkbox'
                    className='sr-only'
                    checked={
                      !priceDiscoveryDataIsLoading &&
                      priceDiscoveryData?.data?.automated
                    }
                    onChange={() => toggleAutomatePricing()}
                  />
                  <div
                    className={
                      !priceDiscoveryDataIsLoading &&
                      priceDiscoveryData?.data?.lastActiveSettings
                        ?.automated === true
                        ? 'block bg-green-400 w-14 h-8 rounded-full'
                        : 'block bg-gray-400 w-14 h-8 rounded-full'
                    }
                  ></div>

                  <div
                    className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                      !priceDiscoveryDataIsLoading &&
                      priceDiscoveryData?.data?.lastActiveSettings
                        ?.automated === true
                        ? 'transform translate-x-full'
                        : ''
                    }`}
                  ></div>
                </div>
              </label>
            </div>
          </div>
          <div className='buy-prices-container p-4  gap-4 flex-col flex items-center w-1/3 rounded-md border-2 border-emerald-400 '>
            <div className='heading font-semibold uppercase text-lg'>
              Update Buy Pricing
            </div>
            <div className='bottom-container'>
              <label className='block font-bold '>Enter in percentage</label>
              <input
                type='number'
                value={buyPricing}
                onChange={(e) => setBuyPricing(e.target.value)}
                className='mt-1 mb-2 w-full px-3 py-2 border bg-slate-100 text-[#202020ff] border-gray-300 rounded-md'
              />{' '}
              <button
                onClick={() => updateBuyPricing()}
                className='login-btn px-2 py-1 w-full  bg-[#01c38e] text-black text-lg font-semibold'
              >
                Update
              </button>
            </div>
          </div>
          <div className='sell-prices-container  p-4  gap-4 flex-col flex items-center w-1/3 rounded-md border-2 border-emerald-400 '>
            <div className='heading font-semibold uppercase text-lg'>
              Update Sell Pricing
            </div>
            <div className='bottom-container'>
              <label className='block font-bold '>Enter in percentage</label>

              <input
                type='number'
                value={sellPricing}
                onChange={(e) => setSellPricing(e.target.value)}
                className='mt-1 mb-2 w-full px-3 py-2 border bg-slate-100 text-[#202020ff] border-gray-300 rounded-md'
              />
              <button
                onClick={() => updateSellPricing()}
                className='login-btn px-2 py-1 w-full bg-[#01c38e] text-black text-lg font-semibold'
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperAdmin;
