import toast, { Toaster } from 'react-hot-toast';
import React, { useState } from 'react';
import {
  FiSettings,
  FiLayers,
  FiLogOut,
  FiLifeBuoy,
  FiSliders,
} from 'react-icons/fi';
import { BiBarChartAlt } from 'react-icons/bi';
import { LuCopyCheck, LuFileClock, LuAward } from 'react-icons/lu';

import ProfilePic from '../assets/User-avatar.svg.png';
import LogoP2px from '../assets/Frame 6140@3x.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserIsAuth } from '../store/uiState';
import Cookies from 'js-cookie';
function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brokerAddress = Cookies.get('brokerAddress');
  const brokerType = Cookies.get('brokerType');

  const location = useLocation();
  const [sidebarState, setSideBarState] = useState(0);

  // Check if the current route is the login page
  const pagename = location.pathname;
  const logout = () => {
    toast.success('Logout Successfull!', {
      duration: 1500,
      position: 'top-center ',
    });

    setTimeout(() => {
      dispatch(setUserIsAuth(false));
      navigate('/login');
      Cookies.remove('brokerAddress');
      Cookies.remove('jwtToken');
      Cookies.remove('isAuth');
    }, 1500);
  };
  return (
    <div
      className='sidebar-container w-1/5 h-screen  border-r-[1px] border-[#EAECF0
] flex flex-col items-start gap-4 p-4 justify-between '
    >
      <Toaster position='top-center' reverseOrder={false} />
      <div className='logo-container flex items-center gap-2 w-full   bg-[#01c38e] border-b-4 p-2 justify-center border-[#202020ff]'>
        <img className='h-8 w-8 ' src={LogoP2px} alt='img' />
        <div className='logo-text text-3xl text-[#202020ff] uppercase'>
          p2px
        </div>
      </div>
      <div className='pages-menu-container w-full flex flex-col h-full justify-between'>
        <ul className='top-pages-component-list w-full flex flex-col gap-2'>
          {/* {console.log(pagename)} */}
          <Link
            to='/dashboard'
            className={`dashbord-page group flex px-3 py-4 h-14 w-full text-black hover:bg-[#ECF9F5] items-center gap-3 rounded-md ${
              sidebarState === 0 ? 'bg-[#ECF9F5]' : ''
            }`}
            onClick={() => setSideBarState(0)}
          >
            <FiLayers className='FiLayers-image h-5  w-5 group-hover:text-[#028D67] group-active:text-[#028D67] object-contain' />

            <div className='dashboard-text text-md capitalize group-hover:text-[#028D67] '>
              Dashboard
            </div>
          </Link>
          <Link
            to='/transactions'
            className={`transactions-page group flex px-3 py-4 h-14 w-full text-black hover:bg-[#ECF9F5] items-center gap-3 rounded-md ${
              sidebarState === 1 ? 'bg-[#ECF9F5]' : ''
            }`}
            onClick={() => setSideBarState(1)}
          >
            {/* <img
              className="BarChartSquare-image  h-5  w-5 group-hover:text-[#028D67] group-active:text-[#028D67] object-contain"
              src={BarChartSquare}
              alt="img"
            /> */}
            <BiBarChartAlt className='BarChartSquare-image  h-5  w-5 group-hover:text-[#028D67] group-active:text-[#028D67] object-contain' />
            <div className='transactions-text text-md capitalize group-hover:text-[#028D67] '>
              Transactions
            </div>
          </Link>
          <Link
            to='/archivedtransactions'
            className={`transactions-page group flex px-3 py-4 h-14 w-full text-black hover:bg-[#ECF9F5] items-center gap-3 rounded-md ${
              sidebarState === 2 ? 'bg-[#ECF9F5]' : ''
            }`}
            onClick={() => setSideBarState(2)}
          >
            {/* <img
              className="BarChartSquare-image  h-5  w-5 group-hover:text-[#028D67] group-active:text-[#028D67] object-contain"
              src={BarChartSquare}
              alt="img"
            /> */}
            <LuFileClock className='BarChartSquare-image  h-5  w-5 group-hover:text-[#028D67] group-active:text-[#028D67] object-contain' />
            <div className='transactions-text text-md capitalize group-hover:text-[#028D67] '>
              Archived Transactions
            </div>
          </Link>
          <Link
            to='/manageproviders'
            className={`manage-providers-page group flex px-3 py-4 h-14 w-full text-black hover:bg-[#ECF9F5] items-center gap-3 rounded-md ${
              sidebarState === 3 ? 'bg-[#ECF9F5]' : ''
            }`}
            onClick={() => setSideBarState(3)}
          >
            <LuCopyCheck className='BarChartSquare-image  h-5  w-5 group-hover:text-[#028D67] group-active:text-[#028D67] object-contain' />
            <div className='manage-providers-text text-md capitalize group-hover:text-[#028D67] '>
              Manage providers
            </div>
          </Link>

          <Link
            to='/reputationsystem'
            className={`reputation-system-page group flex px-3 py-4 h-14 w-full text-black hover:bg-[#ECF9F5] items-center gap-3 rounded-md ${
              sidebarState === 4 ? 'bg-[#ECF9F5]' : ''
            }`}
            onClick={() => setSideBarState(4)}
          >
            <LuAward className='BarChartSquare-image  h-5  w-5 group-hover:text-[#028D67] group-active:text-[#028D67] object-contain' />
            <div className='reputation-system-text text-md capitalize group-hover:text-[#028D67] '>
              Reputation System
            </div>
          </Link>
          {brokerType === 'ADMIN' ? (
            <>
              <Link
                to='/superadmin'
                className={`superAdmin-page group flex px-3 py-4 h-14 w-full text-black hover:bg-[#ECF9F5] items-center gap-3 rounded-md ${
                  sidebarState === 5 ? 'bg-[#ECF9F5]' : ''
                }`}
                onClick={() => setSideBarState(5)}
              >
                <FiSliders className='BarChartSquare-image  h-5  w-5 group-hover:text-[#028D67] group-active:text-[#028D67] object-contain' />
                <div className='superAdmin-page text-md capitalize group-hover:text-[#028D67] '>
                  Super Admin
                </div>
              </Link>
            </>
          ) : (
            ''
          )}
        </ul>

        {/* <ul className='bottom-pages-component-list'>
          <li className='support-page group flex px-3 py-4 h-14 w-full  text-black hover:bg-[#ECF9F5] active:bg-[#ECF9F5] items-center gap-3 rounded-md'>
            <FiLifeBuoy className='LifeBuoyOne-image  h-5  w-5 group-hover:text-[#028D67] group-active:text-[#028D67] object-contain' />
            <div className='support-text text-md capitalize group-hover:text-[#028D67] '>
              Support
            </div>
          </li>
          <li className='settings-page group flex px-3 py-4 h-14 w-full  text-black hover:bg-[#ECF9F5] active:bg-[#ECF9F5] items-center gap-3 rounded-md'>
            <FiSettings className='FiLayers-image h-5  w-5 group-hover:text-[#028D67] group-active:text-[#028D67] object-contain' />
            <div className='settings-text text-md capitalize group-hover:text-[#028D67] '>
              Settings
            </div>
          </li>
        </ul> */}
      </div>
      <div className='profile-component-container  flex w-full items-center gap-4 justify-center  border-[2px] rounded-lg p-2 border-[#01c38e] bg-[#202020ff]'>
        <div className='image-container-left '>
          <img
            className='profile-picture h-6 w-6 object-fill bg-[#01c38e] rounded-full '
            src={ProfilePic}
            alt='img'
          />
        </div>
        <div className='details-container-center w-2/3 cursor-pointer bg- '>
          <div className='user-name text-md capitalize font-semibold  text-[#01c38e]'>
            Broker
          </div>
          <div
            className='user-email text-xs truncate w-3/4   text-[#01c38e]'
            title={brokerAddress}
          >
            {brokerAddress}...
          </div>
        </div>
      </div>
      <div
        className='logout-button settings-page group flex px-3 py-4 h-14 w-full border-2 border-red-300 text-black hover:bg-red-50 active:bg-[#ECF9F5] items-center gap-3 rounded-md justify-center hover:scale-105 cursor-pointer'
        onClick={logout}
      >
        <div className='logout-text text-lg font-semibold capitalize '>
          Logout
        </div>
        <FiLogOut
          className='logout-buton h-3.5 w-3.5 text-red-500  cursor-pointer '
          title='Logout'
        />
      </div>
    </div>
  );
}

export default Sidebar;
