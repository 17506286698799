import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import { MdArchive } from 'react-icons/md';
import { TbExchange } from 'react-icons/tb';
import { CiSearch } from 'react-icons/ci';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import {
  useArchiveTransactionMutation,
  useBrokerTransactionsQuery,
  useGetUpiProviderQuery,
  useRestoreTransactionMutation,
} from '../query-services/brokerApi';
import TransactionData from '../components/Transactions-components/TransactionData';
import ReassignModal from '../components/Transactions-components/ReassignModal';
import Cookies from 'js-cookie';
import notificationSound from '../assets/notificationSoundwav.wav';
import { addSeenTransactions } from '../store/uiState';
function ArchivedTransactions() {
  // const brokerAddress = useSelector(
  //   (state) => state.uiState.userState.brokerAddress
  // );
  const brokerAddress = Cookies.get('brokerAddress');
  const [
    restoreTransaction,
    { data: restoreData, error: restoreError, isLoading: restoreLoading },
    isFetching,
    isSuccess,
  ] = useRestoreTransactionMutation();

  const {
    data: providersData,
    error: providersDataError,
    isLoading: providerDataIsLoading,
    refetch: providerDataRefetch,
  } = useGetUpiProviderQuery(brokerAddress);

  const [transaction, setTransacion] = useState(null);
  const [providers, setProviders] = useState(null);
  const [orderId, setOrderId] = useState(0);
  const [isOpenTransactionModal, setIsOpenTransacionModal] = useState(false);
  const [isOpenReassignModal, setIsOpenReassignModal] = useState(false);
  const [clickedRow, setClickedRow] = useState(null);
  const firstRender = useRef(true);
  const [unarchiving, setUnArchiving] = useState({});

  const { data, error, isLoading } = useBrokerTransactionsQuery(brokerAddress, {
    pollingInterval: 60000,
  });
  const dispatch = useDispatch();
  const seenTransactions = useSelector(
    (state) => state.uiState.userState.seenTransactions
  );

  const openTransactionModal = (transaction) => {
    setTransacion(transaction);
    // console.log(transaction);
    setIsOpenTransacionModal(true);
  };

  const closeModal = () => {
    setIsOpenReassignModal(false);

    setIsOpenTransacionModal(false);
    providerDataRefetch();
    setClickedRow(null);
  };
  console.log('Before filter:', data?.data);

  const sortedData = [...(data?.data || [])]
    .filter((item) => item.deletedAt !== null)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  console.log('After filter:', sortedData);

  const handleRestoreTransaction = async (event, id) => {
    event.stopPropagation();
    setUnArchiving((prev) => ({ ...prev, [id]: true }));
    try {
      const finalRequest = {
        id: id,
        brokerAddress,
      };
      await restoreTransaction(finalRequest);
    } catch (error) {
      console.error('Failed to archive transaction:', error);
    } finally {
      setUnArchiving((prev) => ({ ...prev, [id]: false }));
    }
  };

  const filteredData =
    !providerDataIsLoading && providersData && providersData.data
      ? providersData.data
        ? providersData.data
            .filter(
              ({ blocked, deletedAt, online }) =>
                !blocked && !deletedAt && online
            )
            .map(({ phone, upiId }) => ({ phone, upiId }))
        : []
      : [];
  // console.log(filteredData);

  function checkBuyStatus(contractState, providerState) {
    if (contractState === 'PLACED' && providerState === null) {
      return 'assigning provider';
    }
    if (contractState === 'PLACED' && providerState === 'PENDING') {
      return 'provider assigned';
    }
    if (contractState === 'ACCEPTED' && providerState === 'PENDING') {
      return 'order accepted';
    }
    if (contractState === 'PAID' && providerState === 'PENDING') {
      return 'user paid';
    }
    if (contractState === 'PAID' && providerState === 'PENDING') {
      return 'verifying user payment';
    }
    if (contractState === 'PAID' && providerState === 'PAID_BY_USER') {
      return 'user payment verified';
    }
    if (contractState === 'COMPLETED' && providerState === 'COMPLETE') {
      return 'Completed';
    }
  }

  function checkSellStatus(contractState, providerState) {
    if (contractState === 'PLACED' && providerState === null) {
      return 'order placed';
    }
    if (contractState === 'PLACED' && providerState === 'PENDING') {
      return 'provider assigned';
    }
    if (contractState === 'PLACED' && providerState === 'PAID_BY_USER') {
      return 'user payment verified';
    }
    if (contractState === 'COMPLETED' && providerState === 'COMPLETE') {
      return 'completed';
    }
  }
  function disbleReassignStatus(orderType, contractState, providerState) {
    if (orderType === 'BUY') {
      return true;
    }
    if (orderType === 'SELL' && providerState === null) {
      return true;
    }
    if (orderType === 'SELL' && contractState === 'COMPLETED') {
      return true;
    }
    return false;
  }
  console.log(sortedData);
  return (
    <div className='transactions-container w-full h-screen overflow-y-scroll'>
      <div className='header w-full'>
        <Header />
      </div>
      {/* <div className='transactions-component'>
        <div className='transactions-filter-component flex justify-between items-center text-sm px-4 mb-4'>
          <div className='left-component '>
            <div
              className='trasaction-types flex px-2 py-1 justify-between  rounded-md border-[1px] border-[#EAECF0
] '
            >
              <button className='viewall  border-r-[1px] px-4 py-1 border-[#EAECF0] '>
                View all
              </button>
              <button className='buy border-r-[1px] px-4 py-1 border-[#EAECF0]'>
                Buy Transactions
              </button>
              <button className='sell px-4 py-1'>Sell Transactions</button>
            </div>
          </div>
          <div className='right-component flex items-center gap-4'>
            <div
              className='search-component flex py-2 px-1 w-[260px] border-[1px] border-[#EAECF0
] rounded-md items-center'
            >
              <CiSearch className='h-5 w-5 mx-2 text-gray-600' />
              <input
                type='search'
                id='default-search'
                className='search-input outline-none h-full w-full'
                placeholder='Search '
              />
            </div>
            <div className='filter-component flex gap-4 border-[1px] border-[#EAECF0] items-center px-2 py-1 capitalize rounded-md'>
              <IoFilterOutline />
              <div className='filter-text'>filters</div>
            </div>
          </div>
        </div>
      </div> */}

      {isOpenTransactionModal && (
        <TransactionData transaction={transaction} onClose={closeModal} />
      )}
      {isOpenReassignModal && (
        <ReassignModal
          providers={filteredData}
          orderId={orderId}
          closeModal={closeModal}
        />
      )}
      <div className='transactions-table w-full'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-[#202020ff] '>
            <tr>
              <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                Vendor
              </th>
              <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                Buy / Sell
              </th>
              <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                Status
              </th>
              <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                transaction Time
              </th>
              {/* <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                Reassign transaction
              </th> */}
              <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                Restore Transaction
              </th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {!isLoading && sortedData && sortedData.length > 0 ? (
              sortedData.map((item, i) => {
                if (!item) return null;

                const date = new Date(item.createdAt);
                const timeAgo = formatDistanceToNow(date, {
                  addSuffix: true,
                });
                const formattedDate = ` ${date.toLocaleTimeString()} ${date.toLocaleDateString(
                  'en-GB',
                  {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }
                )}`;

                return (
                  <tr
                    key={i}
                    onClick={() => {
                      openTransactionModal(item);
                      setClickedRow(i);
                    }}
                    className={`$ hover:bg-gray-100 ${
                      clickedRow === i ? 'bg-gray-100' : ''
                    }`}
                  >
                    <td className='px-6 py-4 whitespace-nowrap'>
                      <div className='text-sm text-gray-900 text-center'>
                        {item.providerPhone}
                      </div>
                      <div className='text-sm text-gray-500 capitalize font-mono font-semibold text-center'>
                        {item?.Provider?.name}
                      </div>
                    </td>

                    <td className='px-6 py-4 whitespace-nowrap'>
                      <div className='text-md text-gray-700 font-bold'>
                        {`${item.orderType} - $ ${(
                          item.amount / 1000000
                        ).toFixed(2)}`}{' '}
                      </div>
                      <div className='text-sm text-gray-500 font-mono '>
                        {`Id: ${item.id}`}
                      </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${
                          item.providerState === 'COMPLETE'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {item.orderType === 'BUY'
                          ? checkBuyStatus(
                              item.contractState,
                              item.providerState
                            )
                          : checkSellStatus(
                              item.contractState,
                              item.providerState
                            )}
                      </span>
                    </td>

                    <td className='px-6 py-4 whitespace-nowrap'>{timeAgo}</td>

                    <td className='px-6 py-4 whitespace-nowrap  '>
                      {unarchiving[item.id] ? (
                        <button
                          className={`text-red-400 bg-black font-bold py-2 flex items-center gap-2
px-3 rounded transition duration-500 ease-in-out transform hover:-translate-y-1  hover:scale-105   justify-end`}
                        >
                          <div className='animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-4'></div>
                          <span className='font-mono font-semibold'>
                            {' '}
                            Restoring
                          </span>
                        </button>
                      ) : (
                        <button
                          onClick={(event) =>
                            handleRestoreTransaction(event, item.id)
                          }
                          className={`text-red-400 bg-black font-bold py-2 flex items-center gap-2
px-3 rounded transition duration-500 ease-in-out transform hover:-translate-y-1  hover:scale-105   justify-end`}
                        >
                          <MdArchive />
                          <span className='font-mono font-semibold'>
                            {' '}
                            Restore
                          </span>
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan='5' className='px-6 py-4 text-center'>
                  No Transactions Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ArchivedTransactions;
