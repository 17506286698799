import React, { useState } from 'react';
import LogoP2px from '../assets/Frame 6140@3x.svg';
import {
  useBrokerTransactionsQuery,
  useBrokerAuthenticationMutation,
} from '../query-services/brokerApi';
import { useDispatch, useSelector } from 'react-redux';
import { setUserIsAuth, setBrokerAddress } from '../store/uiState';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Toaster, toast } from 'react-hot-toast';

function Login() {
  const [loginId, setLoginId] = useState('');

  const [brokerAuthentication, data, error, isLoading, isFetching, isSuccess] =
    useBrokerAuthenticationMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    // console.log(loginId);

    await brokerAuthentication(loginId);
  };

  // console.log('yii' + data);

  if (!isLoading && data && data.data && data.data.statusCode === 200) {
    // console.log('ishould be here' + data);

    toast.success('login Successfull');
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 7);
    // console.log(data.data.statusCode);
    Cookies.set('jwtToken', data?.data?.secretToken, { expires: expiryDate });
    Cookies.set('isAuth', true, { expires: expiryDate });
    Cookies.set('brokerType', data?.data?.data?.brokerType, {
      expires: expiryDate,
    });
    Cookies.set('brokerAddress', data?.data?.data?.publicAddress, {
      expires: expiryDate,
    });

    // console.log(data.data.data.publicAddress);
    dispatch(setUserIsAuth(true));
    // console.log(data.data);
    dispatch(setBrokerAddress(data.data.publicAddress));
    navigate('/dashboard');
  }

  // console.log(data);
  return (
    <div className='login-container flex items-center w-screen h-screen justify-center'>
      <Toaster position='top-center' reverseOrder={false} />

      <div className='login-component w-[480px] bg-gray-100  h-[280px] flex flex-col items-center justify-center gap-4'>
        {/* {console.log(loginId)} */}
        <img className='h-8 w-8 ' src={LogoP2px} alt='img' />
        <div className='login-text text-2xl  font-medium'>
          Enter your login key
        </div>
        <input
          onChange={(e) => setLoginId(e.target.value)}
          type='text '
          name='login'
          value={loginId}
          className='login-input px-2 py-2 w-[240px] outline-none '
          required
        />
        <button
          onClick={handleSubmit}
          className='login-btn px-2 py-1 w-[240px] bg-teal-300 text-black text-lg font-semibold'
        >
          Login
        </button>
        {/* {console.log(data)}
        {console.log(error)}
        {console.log(isFetching)} */}
      </div>
    </div>
  );
}

export default Login;
