import React from 'react';
import Header from '../components/Header';
import MetricsComponent from '../components/Dashboard-components/MetricsComponent';
import SalesReport from '../components/Dashboard-components/SalesReport';
import { useSelector } from 'react-redux';
import {
  useBrokerTransactionsQuery,
  useGetAccountingDataQuery,
  useGetUpiProviderQuery,
  useGetWalletBalanceQuery,
} from '../query-services/brokerApi';
import Cookies from 'js-cookie';

function Dashboard() {
  const brokerAddress = Cookies.get('brokerAddress');
  const {
    data: providersData,
    error: providersDataError,
    isLoading: providerDataIsLoading,
    refetch: providerDataRefetch,
  } = useGetUpiProviderQuery(brokerAddress);
  const {
    data: walletData,
    error: walletError,
    isLoading: walletLoading,
  } = useGetWalletBalanceQuery(brokerAddress);
  const {
    data: accountingData,
    error: accountingDataError,
    isLoading: accountingDataLoading,
  } = useGetAccountingDataQuery(brokerAddress);

  const { data, error, isLoading } = useBrokerTransactionsQuery(brokerAddress, {
    pollingInterval: 60000,
  });

  let walletBalance = 0;
  let voterRewardBalance = 0;
  let gasBalance = 0;
  let totalAmount = 0;
  let transactionsLast24Hours = [];
  let cumulativeAmountLast24Hours = 0;

  if (!walletLoading && walletData) {
    walletBalance = walletData.data.walletBalance;
  }
  if (!walletLoading && walletData) {
    voterRewardBalance = walletData.data.voterRewardBalance;
  }
  if (!walletLoading && walletData) {
    gasBalance = walletData.data.gasBalance;
  }

  if (!isLoading && data) {
    const currentTime = new Date().getTime();
    transactionsLast24Hours = data.data.filter((transaction) => {
      const transactionTime = new Date(transaction.createdAt).getTime();
      return currentTime - transactionTime <= 24 * 60 * 60 * 1000; // Check if the transaction is within the last 24 hours
    });

    totalAmount = data.data
      .filter(
        (transaction) =>
          transaction.contractState === 'COMPLETED' &&
          transaction.providerState === 'COMPLETE'
      )
      .reduce((total, item) => total + item.amount, 0);
    cumulativeAmountLast24Hours = transactionsLast24Hours
      .filter(
        (transaction) =>
          transaction.contractState === 'COMPLETED' &&
          transaction.providerState === 'COMPLETE'
      )
      .reduce((total, item) => total + item.amount, 0);
    console.log(totalAmount);
    console.log(cumulativeAmountLast24Hours);
  }
  let totalCompletedTransactions = 0;
  //
  if (!isLoading && data) {
    totalCompletedTransactions = data.data.filter(
      (transaction) =>
        transaction.contractState === 'COMPLETED' &&
        transaction.providerState === 'COMPLETE'
    );
  }
  console.log(totalCompletedTransactions);

  let totalCompletedTransactionsAverage = 0;

  if (!isLoading && data) {
    const completedTransactions = data.data.filter(
      (transaction) =>
        transaction.contractState === 'COMPLETED' &&
        transaction.providerState === 'COMPLETE'
    );

    const transactionAmounts = completedTransactions.map(
      (transaction) => transaction.amount
    );
    const totalTransactionAmount = transactionAmounts.reduce(
      (a, b) => a + b,
      0
    );

    if (completedTransactions.length > 0) {
      totalCompletedTransactionsAverage =
        totalTransactionAmount / completedTransactions.length;
    }
  }

  console.log(totalCompletedTransactionsAverage);

  let totalActiveProviders = 0;
  if (providersData) {
    totalActiveProviders = providersData.data.filter(
      (provider) =>
        !provider.deletedAt && (provider.sellOnline || provider.buyOnline)
    );
  }

  console.log(totalActiveProviders);
  let totalInActiveProviders = 0;
  if (providersData) {
    totalInActiveProviders = providersData.data.filter(
      (provider) => !provider.deletedAt
    );
  }

  console.log(totalInActiveProviders);
  return (
    <div className='dashboard-container w-4/5  px-6 flex flex-col'>
      {/* <div className="dashboard-left-container w-1/5">
        <Sidebar />
      </div> */}
      <div className='dashboard-right-container w-full flex flex-col gap-4  '>
        <div className='header w-full'>
          <Header />
        </div>

        <div className='metrics-container flex justify-between  flex-wrap gap-4 '>
          <MetricsComponent
            type={'Cumulative Volume'}
            amount={totalAmount / 1000000}
            prefix={'$'}
          />
          <MetricsComponent
            type={'Last 24hr Vol'}
            amount={cumulativeAmountLast24Hours / 1000000}
            prefix={'$'}
          />
          <MetricsComponent
            type={'Wallet Balance'}
            amount={(walletBalance / 1000000).toFixed(2)}
            prefix={'$'}
          />{' '}
          <MetricsComponent
            type={'Total Completed Transactions'}
            amount={totalCompletedTransactions.length}
            prefix={''}
          />{' '}
          <MetricsComponent
            type={'Average Completed Transactions Value '}
            amount={(totalCompletedTransactionsAverage / 1000000).toFixed(2)}
            prefix={'$'}
          />{' '}
          <MetricsComponent
            type={'Available Providers '}
            amount={totalActiveProviders.length}
            prefix={'🟢'}
            total={totalInActiveProviders.length}
          />{' '}
          <MetricsComponent
            type={'Voter Reward Balance '}
            amount={(voterRewardBalance / 1000000).toFixed(2)}
            prefix={'$'}
          />
          <MetricsComponent
            type={'Broker Gas Balance '}
            amount={(gasBalance / 1000000000000000000).toFixed(2)}
            prefix={''}
            postfix={'Matic'}
          />{' '}
          <MetricsComponent
            type={'Average Buy Volume / Avg Price '}
            amount={
              Number(
                accountingData?.data?.avgPricesAndVolumes[0]?.totalVolume /
                  1000000
              )?.toFixed(2) + ' /'
            }
            prefix={'$'}
            postfix={
              '$ ' +
              Number(
                accountingData?.data?.avgPricesAndVolumes[0]?.avgPrice
              )?.toFixed(2)
            }
          />
          <MetricsComponent
            type={'Average Sell Volume / Avg Price  '}
            amount={
              Number(
                accountingData?.data?.avgPricesAndVolumes[1]?.totalVolume /
                  1000000
              )?.toFixed(2) + ' /'
            }
            prefix={'$'}
            postfix={
              '$ ' +
              Number(
                accountingData?.data?.avgPricesAndVolumes[1]?.avgPrice
              )?.toFixed(2)
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
