import React, { useEffect, useState } from 'react';
import {
  useBrokerTransactionsQuery,
  useReconciliationDataQuery,
} from '../../query-services/brokerApi';
import Cookies from 'js-cookie';
import { formatDistanceToNow } from 'date-fns';

function ProvidersDataModal({ closeModal, providerData }) {
  const brokerAddress = Cookies.get('brokerAddress');
  const { data, error, isLoading } = useBrokerTransactionsQuery(brokerAddress, {
    pollingInterval: 60000,
  });
  const {
    data: reconciliationData,
    error: reconciliationError,
    isLoading: reconciliationIsLoading,
  } = useReconciliationDataQuery(brokerAddress);

  const providerTransactions = [...(data?.data || [])].filter(
    (item) => item.providerPhone === providerData.phone
  );
  //   const providerReconciliationData = [
  //     ...(reconciliationData?.data || []),
  //   ].filter((item) => item.providerPhone === providerData.phone);
  const [monthlyVolume, setMonthlyVolume] = useState({});

  useEffect(() => {
    if (!reconciliationIsLoading && reconciliationData) {
      // Extract monthly volume data for the specific phone number
      const phoneData = reconciliationData.data[providerData.phone];
      setMonthlyVolume(phoneData);
    }
  }, [reconciliationIsLoading, reconciliationData, providerData.phone]);
  console.log(monthlyVolume);
  const keyValuePairs = monthlyVolume ? Object.entries(monthlyVolume) : [];
  const values = monthlyVolume ? Object.values(monthlyVolume) : [];
  const sum = values.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const sortedData = [...(data?.data || [])]
    .filter(
      (item) =>
        item.deletedAt === null && item.providerPhone === providerData.phone
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  console.log(sortedData);
  function checkBuyStatus(contractState, providerState) {
    if (contractState === 'PLACED' && providerState === null) {
      return 'assigning provider';
    }
    if (contractState === 'PLACED' && providerState === 'PENDING') {
      return 'provider assigned';
    }
    if (contractState === 'ACCEPTED' && providerState === 'PENDING') {
      return 'order accepted';
    }
    if (contractState === 'PAID' && providerState === 'PENDING') {
      return 'user paid';
    }
    if (contractState === 'PAID' && providerState === 'PENDING') {
      return 'verifying user payment';
    }
    if (contractState === 'PAID' && providerState === 'PAID_BY_USER') {
      return 'user payment verified';
    }
    if (contractState === 'COMPLETED' && providerState === 'COMPLETE') {
      return 'Completed';
    }
  }

  function checkSellStatus(contractState, providerState) {
    if (contractState === 'PLACED' && providerState === null) {
      return 'order placed';
    }
    if (contractState === 'PLACED' && providerState === 'PENDING') {
      return 'provider assigned';
    }
    if (contractState === 'PLACED' && providerState === 'PAID_BY_USER') {
      return 'user payment verified';
    }
    if (contractState === 'COMPLETED' && providerState === 'COMPLETE') {
      return 'completed';
    }
  }
  console.log(sum);
  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        {/* Background overlay */}
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>

        {/* Modal content */}
        <div className='modal-inline text-left inline-block align-bottom bg-white rounded-lg  overflow-hidden shadow-xl   transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full'>
          <div className='bg-white px-4 pt-5 w-full pb-4 sm:p-6 sm:pb-4'>
            {/* Close button */}
            <div className='text-right'>
              <button
                onClick={closeModal}
                className='text-gray-500 text-2xl font-mono hover:text-gray-700 focus:outline-none'
              >
                X
              </button>
            </div>

            {/* Content */}
            <div className='mt-5 sm:mt-5'>
              <div className='heading-container text-2xl font-semibold text-center mb-4'>
                Provider Data of {providerData.name}
              </div>
              <div className=''>
                {/* Monthly Volume Section */}
                <div className='p-2 overflow-x-auto h-25 '>
                  <h3 className='text-lg font-medium leading-6 text-gray-900'>
                    Monthly Volume
                  </h3>

                  {monthlyVolume ? (
                    <>
                      {Object.entries(monthlyVolume).map(([key, value]) => (
                        <div className='flex gap-2' key={key}>
                          <span>{key} : </span>
                          <span> {(value / 1000000).toFixed(2)}</span>
                        </div>
                      ))}
                      <h3 className='text-lg font-medium leading-6 mt-4 text-gray-900'>
                        Total Volume : ₹ {(sum / 1000000).toFixed(2)}
                      </h3>
                    </>
                  ) : (
                    <p>No Monthly Volume Data Available</p>
                  )}
                </div>
                {/* Transactions Section */}
                <div className='w-full p-2'>
                  <h3 className='text-xl font-medium leading-6 text-gray-900  '>
                    Transactions
                  </h3>
                  {/* <div className='mt-4'> */}
                  {/* Render your transaction data here */}
                  {/* <ul>
                      {providerTransactions.map((transaction, index) => (
                        <li key={index} className='mb-2'>
                          {transaction.createdAt}: {transaction.amount}
                        </li>
                      ))}
                    </ul> */}
                  {/* </div> */}
                  <div className='mt-4 w-full'>
                    <div className='overflow-x-auto overflow-y-scroll h-48  w-full'>
                      <table className='min-w-full divide-y divide-gray-200  w-full'>
                        <thead className='bg-[#202020ff] '>
                          <tr>
                            <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                              Buy / Sell
                            </th>
                            <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                              Status
                            </th>
                            <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                              Transaction Time
                            </th>
                          </tr>
                        </thead>
                        <tbody className='bg-white divide-y divide-gray-200'>
                          {!isLoading && sortedData && sortedData.length > 0 ? (
                            sortedData.map((item, i) => {
                              if (!item) return null;

                              const date = new Date(item.createdAt);
                              const timeAgo = formatDistanceToNow(date, {
                                addSuffix: true,
                              });
                              // Format date

                              return (
                                <tr key={i} className={` hover:bg-gray-100`}>
                                  <td className='px-6 py-4 whitespace-nowrap'>
                                    <div className='text-md text-gray-700 font-bold'>
                                      {`${item.orderType} - $ ${(
                                        item.amount / 1000000
                                      ).toFixed(2)}`}{' '}
                                    </div>
                                    <div className='text-sm text-gray-500 font-mono '>
                                      {`Id: ${item.id}`}
                                    </div>
                                  </td>
                                  <td className='px-6 py-4 whitespace-nowrap'>
                                    <span
                                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${
                                        item.providerState === 'COMPLETE'
                                          ? 'bg-green-100 text-green-800'
                                          : 'bg-yellow-100 text-yellow-800'
                                      }`}
                                    >
                                      {item.orderType === 'BUY'
                                        ? checkBuyStatus(
                                            item.contractState,
                                            item.providerState
                                          )
                                        : checkSellStatus(
                                            item.contractState,
                                            item.providerState
                                          )}
                                    </span>
                                  </td>

                                  <td className='px-6 py-4 whitespace-nowrap'>
                                    {timeAgo}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan='5' className='px-6 py-4 text-center'>
                                No Transactions Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal footer */}
          <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
            <button
              onClick={closeModal}
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#01c38e] text-base font-medium text-white hover:bg-[#01a87e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#01c38e] sm:ml-3 sm:w-auto sm:text-sm'
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProvidersDataModal;
