import React from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';

function MetricsComponent({ type, amount, prefix, total, postfix }) {
  return (
    <div
      className='metrics-container bg-white  border-[1px] border-[#EAECF0
]  w-72 h-[5.5rem] flex flex-col items-center justify-center gap-1 p-4 rounded-md'
    >
      <div className='top-container flex justify-center text-xs items-baseline w-full  text-gray-600'>
        <div className='metric-name'>{type}</div>
        {/* <BiDotsVerticalRounded className='text-lg ' /> */}
      </div>
      <div className='bottom-container flex justify-center items-baseline w-full'>
        <div className='amount text-2xl font-medium'>
          {prefix} {amount} {total ? '/ ' + total : ''} {postfix}
        </div>
        {/* <div className='percentage-change px-1 rounded-xl text-xs text-[#028D67] font-semibold bg-[#ECF9F5]'>
          + 12%{' '}
        </div> */}
      </div>
    </div>
  );
}

export default MetricsComponent;
