import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
const token = Cookies.get('jwtToken');

export const upiProviderApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_APP_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', `JWT ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createUpiProvider: builder.mutation({
      query: (upiProviderData) => ({
        url: `/upi-provider`,
        method: 'POST',
        body: upiProviderData,
      }),
      invalidatesTags: [{ type: 'UpiProvider' }],
    }),
    updateUpiProvider: builder.mutation({
      query: (upiProviderData) => ({
        url: `/upi-provider`,
        method: 'PATCH',
        body: upiProviderData,
      }),
      invalidatesTags: [{ type: 'UpiProvider' }],
    }),
    getUpiProvider: builder.query({
      query: (brokerAddress) => ({
        url: `/upi-providers?brokerAddress=${brokerAddress}`,
      }),
      providesTags: [{ type: 'UpiProvider' }],
    }),
  }),
});

export const {
  useCreateUpiProviderMutation,
  useUpdateUpiProviderMutation,
  useGetUpiProviderQuery,
} = upiProviderApi;
