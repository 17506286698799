import Cookies from 'js-cookie';
import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
// import { useSelector } from 'react-redux';

function Header() {
  const brokerAddress = Cookies.get('brokerAddress');

  return (
    <div
      className='header-container flex justify-between w-full bg-white p-6 h-fit border-b-[2px] border-[#EAECF0
] mb-6'
    >
      <div className='header-left-container flex flex-col justify-start gap-2'>
        <div className='username text-2xl  w-2/3 font-medium truncate'>
          {' '}
          Connected to {brokerAddress}...
        </div>
        <div className='position uppercase text-xs  tracking-wider text-[#01c38e] font-medium'>
          Group Manager
        </div>
      </div>
      {/* <div className='header-right-container flex items-center'>
        <div className='notification-text text-sm cursor-pointer'>
          No new notifications
        </div>
        <div className='notification-text  cursor-pointer'>
          <BiChevronDown className='w-4 h-4' />
        </div>
      </div> */}
    </div>
  );
}

export default Header;
