import { createSlice, combineReducers } from '@reduxjs/toolkit';

const userState = {
  isAuth: false,
  brokerAddress: '',
  seenTransactions: [],
};

export const uiState = createSlice({
  name: 'uiState',
  initialState: {
    userState,
  },
  reducers: {
    setUserIsAuth: (state, action) => {
      // console.log(action.payload);
      state.userState.isAuth = action.payload;
    },
    setBrokerAddress: (state, action) => {
      // console.log(action.payload);
      state.userState.brokerAddress = action.payload;
    },
    addSeenTransactions: (state, action) => {
      // console.log(action.payload);
      state.userState.seenTransactions = [
        ...state.userState.seenTransactions,
        ...action.payload,
      ];
    },
  },
});

export default uiState.reducer;

export const { setUserIsAuth, setBrokerAddress, addSeenTransactions } =
  uiState.actions;
