import React, { useState } from 'react';
import Header from '../components/Header';
import RegisterProviderModal from '../components/ManageProviders-components/RegisterProviderModal';
import { FiMoreVertical, FiEdit } from 'react-icons/fi';
import { BsFillArrowUpRightSquareFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import {
  useBrokerTransactionsQuery,
  useBuySellStatusMutation,
  useDisableProviderMutation,
  useEnableProviderMutation,
  useGetUpiProviderQuery,
} from '../query-services/brokerApi';
import { useNavigate } from 'react-router-dom';
import EditProviderModal from '../components/ManageProviders-components/EditProviderModal';
import Cookies from 'js-cookie';
import MetricsComponent from '../components/Dashboard-components/MetricsComponent';
import AmountTransferModal from '../components/ManageProviders-components/AmountTransferModal';
import ProvidersDataModal from '../components/ManageProviders-components/ProvidersDataModal';

function ManageProviders() {
  // const brokerAddress = useSelector(
  //   (state) => state.uiState.userState.brokerAddress
  // );
  const brokerAddress = Cookies.get('brokerAddress');
  const { data, error, isLoading, isFetching, isSuccess } =
    useGetUpiProviderQuery(brokerAddress, {
      pollingInterval: 300000,
    });
  // console.log(data);
  const navigate = useNavigate();

  const {
    data: currentBalanceData,
    error: currentBalanceDataError,
    isLoading: currentBalanceDataIsLoading,
  } = useBrokerTransactionsQuery(brokerAddress);
  const [
    disableProvider,
    {
      data: providerIsDisabled,
      error: providerIsDisabledError,
      isLoading: providerIsDisabledIsLoding,
    },
  ] = useDisableProviderMutation();
  const [
    enableProvider,
    {
      data: providerIsEnabled,
      error: providerIsEnabledError,
      isLoading: providerIsEnabledIsLoding,
    },
  ] = useEnableProviderMutation();
  const [
    buySellStatus,
    {
      data: buySellStatusData,
      error: buySellStatusError,
      isLoading: buySellStatusIsLoding,
    },
  ] = useBuySellStatusMutation();
  const [isRegisterModalVisible, setRegisterModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isAmountTransferModalVisible, setAmountTransferModalVisible] =
    useState(false);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null); // new state for active dropdown
  const toggleDropdown = (event, id) => {
    event.stopPropagation();
    setActiveDropdownIndex(activeDropdownIndex === id ? null : id);
  };
  const [amountToBeTransferred, setAmountToBeTransferred] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);
  const [isOpenTransactionModal, setIsOpenTransacionModal] = useState(false);
  const [providerData, setProviderData] = useState(null);
  const [providerTransactionsData, setProviderTransactionsData] =
    useState(null);
  const brokerType = Cookies.get('brokerType');

  const [editItem, setEditItem] = useState(null);
  const [buyStatus, setBuyStatus] = useState(null);
  const [sellStatus, setSellStatus] = useState(null);
  const [amountActionType, setAmountActionType] = useState('null');
  // const [isEnabled, setIsEnabled] = useState();
  const openEditModal = (event, item) => {
    event.stopPropagation();

    // Modify this function to accept an item
    setEditItem(item);
    toggleDropdown(event, null);
    setEditModalVisible(true);
  };

  const openRegisterModal = () => {
    setRegisterModalVisible(true);
  };
  const openProvidersDataModal = (event, item) => {
    event.stopPropagation();
    toggleDropdown(event, null);

    setProviderData(item);
    // console.log(transaction);
    setIsOpenTransacionModal(true);
  };
  const closeModal = () => {
    setRegisterModalVisible(false);
    setEditModalVisible(false);
    setAmountTransferModalVisible(false);
    setIsOpenTransacionModal(false);
  };
  let phoneOrders = new Map();

  !currentBalanceDataIsLoading &&
    currentBalanceData &&
    currentBalanceData.data.forEach((item) => {
      if (item.providerPhone !== null) {
        // If this phone number hasn't been seen before, add a new entry to the Map
        if (!phoneOrders.has(item.providerPhone)) {
          phoneOrders.set(item.providerPhone, {
            buyAmount: 0,
            sellAmount: 0,
            totalAmount: 0,
          });
        }
        // Get the current amounts for this phone number
        let amounts = phoneOrders.get(item.providerPhone);
        //ADD CONDITION OF VERIFIED
        if (
          item.contractState === 'COMPLETED' &&
          item.providerState === 'COMPLETE'
        ) {
          if (item.orderType === 'BUY') {
            amounts.buyAmount += item.inrAmount;
          } else if (item.orderType === 'SELL') {
            amounts.sellAmount += item.inrAmount;
          }
        }

        phoneOrders.set(item.providerPhone, amounts);
        amounts.totalAmount = amounts.buyAmount - amounts.sellAmount;
      }
    });
  let cumulativeTotalAmount = 0;

  // for (let [phone, order] of phoneOrders) {
  //   cummilativetotalAmount += order.totalAmount;
  // }
  data?.data?.forEach((provider) => {
    if (provider.deletedAt === null) {
      cumulativeTotalAmount += provider.amount;
    }
  });
  console.log(cumulativeTotalAmount);
  console.log(phoneOrders);
  // !isLoading && console.log(data.data);

  // const handleAction = (action, id) => {
  //   navigate('/register');
  // };
  // const toggleProviderState = (item) => {
  //   if (item.deletedAt === null) {
  //     disableTheProvider(item);
  //   } else {
  //     enableTheProvider(item);
  //   }
  // };
  const calculateBalance = (item) => {
    console.log(item);
    console.log(phoneOrders);
    return phoneOrders.has(item.phone)
      ? (
          phoneOrders.get(item.phone).buyAmount / 1000000 -
          phoneOrders.get(item.phone).sellAmount / 1000000 +
          item.transferredAmount
        ).toFixed(2)
      : item.transferredAmount;
  };

  const openTransferAmountModal = (event, item) => {
    event.stopPropagation();
    toggleDropdown(event, null);

    setAmountActionType('AmountTransfer');
    setEditItem(item);

    setAmountToBeTransferred(item.amount);
    setAmountTransferModalVisible(true);
  };
  const openAmountUpdateModal = (event, item) => {
    event.stopPropagation();
    toggleDropdown(event, null);

    setAmountActionType('AmountUpdate');
    setEditItem(item);

    setAmountTransferModalVisible(true);
  };
  const toggleBuyStatus = async (event, item) => {
    setBuyStatus(item.buyOnline);
    const toggleBuyStatusData = {
      phone: item.phone,
      brokerAddress,
      type: 'buy',
      status: !item.buyOnline,
    };
    // console.log(item);
    await buySellStatus(toggleBuyStatusData);
  };
  const toggleSellStatus = async (event, item) => {
    setSellStatus(item.sellOnline);

    const toggleSellStatusData = {
      phone: item.phone,
      brokerAddress,
      type: 'sell',
      status: !item.sellOnline,
    };
    // console.log(item);
    await buySellStatus(toggleSellStatusData);
  };

  const disableTheProvider = async (event, item) => {
    event.nativeEvent.stopImmediatePropagation();
    const EnableDisableRequest = {
      phone: item.phone,
      brokerAddress,
    };
    // console.log(item);
    await disableProvider(EnableDisableRequest);
  };

  return (
    <div className='manage-providers-container w-4/5'>
      <div className='header w-full'>
        <Header />
      </div>
      <div
        className={` ${
          brokerType == 'ADMIN'
            ? ' top-container  flex w-full justify-between items-center p-4'
            : 'top-container  flex w-full gap-8 justify-between max-w-2xl items-center p-4'
        }`}
      >
        <div className='upi-providers-title text-2xl flex   uppercase font-semibold'>
          Upi providers
        </div>
        <div className='flex  self-center'>
          <MetricsComponent
            type={'Cummilative Current Balance'}
            amount={cumulativeTotalAmount?.toFixed(2)}
            prefix={'INR'}
          />
        </div>
        {brokerType === 'ADMIN' ? (
          <button
            className='Add-provider bg-[#01c38e] font-bold py-2 flex items-center gap-2
px-3 rounded transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-black hover:scale-105 hover:text-[#01c38e] text-black justify-end'
            onClick={openRegisterModal}
          >
            + Add Provider
          </button>
        ) : (
          ''
        )}
      </div>
      {isOpenTransactionModal && (
        <ProvidersDataModal
          providerData={providerData}
          closeModal={closeModal}
        />
      )}{' '}
      {isRegisterModalVisible && (
        <RegisterProviderModal closeModal={closeModal} />
      )}{' '}
      {isEditModalVisible && (
        <EditProviderModal closeModal={closeModal} item={editItem} /> // Pass the item to be edited
      )}{' '}
      {isAmountTransferModalVisible && (
        <AmountTransferModal
          closeModal={closeModal}
          item={editItem}
          amount={amountToBeTransferred !== null && amountToBeTransferred}
          type={amountActionType}
          providersData={!isLoading && data?.data}
        />
      )}
      <div className='providers-table '>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-[#202020ff] '>
            <tr>
              <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                Name
              </th>
              <th className='px-6 py-3 text-left text-sm font-medium text-[#01c38e] uppercase tracking-wider'>
                Phone
              </th>
              <th className='px-6 py-3 text-left text-sm font-medium  text-[#01c38e] uppercase tracking-wider'>
                UPI ID
              </th>
              <th className='px-6 py-3 text-left text-sm font-medium  text-[#01c38e] uppercase tracking-wider'>
                Current Balance
              </th>
              <th className='px-6 py-3 text-left text-sm font-medium  text-[#01c38e] uppercase tracking-wider'>
                Tier
              </th>

              <th className='px-6 py-3 text-left text-sm font-medium  text-[#01c38e] uppercase tracking-wider'>
                Buy Online
              </th>
              <th className='px-6 py-3 text-left text-sm font-medium  text-[#01c38e] uppercase tracking-wider'>
                Sell Online
              </th>
              {brokerType == 'ADMIN' ? (
                <th className='px-6 py-3 text-left text-xs font-medium  text-[#01c38e] uppercase tracking-wider'>
                  Action
                </th>
              ) : (
                ''
              )}
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {isLoading ? (
              <div className='flex justify-center items-center h-full w-full  '>
                <div className='animate-spin rounded-full h-10 w-10 border-t-2 top-1/2 text-center border-b-2 border-gray-900'></div>
              </div>
            ) : data?.data?.length === 0 ? (
              <tr>
                <td colSpan='6' className='px-6 py-4 text-center'>
                  No Providers
                </td>
              </tr>
            ) : (
              data?.data
                .filter((item) => item.deletedAt === null)
                .map((item, i) => (
                  <tr
                    onClick={() => {
                      setClickedRow(i);
                    }}
                    key={item.phone}
                    className={` border-b-[1px] border-gray-200  hover:bg-gray-100 ${
                      clickedRow === i ? 'bg-gray-100' : ''
                    }`}
                  >
                    {/* {isEditModalVisible && (
                    <EditProviderModal closeModal={closeModal} item={item} />
                  )} */}
                    <td
                      className='px-6 py-4 whitespace-nowrap flex items-center justify-between cursor-pointer gap-2'
                      onClick={(event) => openProvidersDataModal(event, item)}
                    >
                      {item.name}{' '}
                      <BsFillArrowUpRightSquareFill color='#01c38e' />
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                      {item.phone}
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                      {item.upiId}
                    </td>

                    <td className='px-6 py-4 whitespace-nowrap'>
                      {item?.amount?.toFixed(2)}
                      {/* {phoneOrders.has(item.phone)
                        ? 'INR ' +
                          (
                            phoneOrders.get(item.phone).buyAmount / 1000000 -
                            phoneOrders.get(item.phone).sellAmount / 1000000 +
                            item.transferredAmount
                          ).toFixed(2)
                        : 'INR ' + item.transferredAmount} */}
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>{item.tier}</td>

                    <td className='px-6 py-4 whitespace-nowrap'>
                      <label className='flex items-center cursor-pointer'>
                        <div className='relative'>
                          <input
                            type='checkbox'
                            className='sr-only'
                            checked={item.buyOnline === true}
                            onChange={(event) => toggleBuyStatus(event, item)}
                          />
                          <div
                            className={
                              item.buyOnline === true
                                ? 'block bg-green-400 w-14 h-8 rounded-full'
                                : 'block bg-gray-400 w-14 h-8 rounded-full'
                            }
                          ></div>
                          <div
                            className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                              item.buyOnline === true
                                ? 'transform translate-x-full'
                                : ''
                            }`}
                          ></div>
                        </div>
                      </label>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                      <label className='flex items-center cursor-pointer'>
                        <div className='relative'>
                          <input
                            type='checkbox'
                            className='sr-only'
                            checked={item.sellOnline === true}
                            onChange={(event) => toggleSellStatus(event, item)}
                          />
                          <div
                            className={
                              item.sellOnline === true
                                ? 'block bg-green-400 w-14 h-8 rounded-full'
                                : 'block bg-gray-400 w-14 h-8 rounded-full'
                            }
                          ></div>
                          <div
                            className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                              item.sellOnline === true
                                ? 'transform translate-x-full'
                                : ''
                            }`}
                          ></div>
                        </div>
                      </label>
                    </td>
                    {brokerType == 'ADMIN' ? (
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='relative inline-block text-left'>
                          {/* <div>
                        <button
                          onClick={() => openEditModal(item)}
                          type='button'
                          className='inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                          <FiEdit className='mr-2' />
                          Edit
                        </button>
                      </div> */}

                          <button
                            onClick={(event) =>
                              toggleDropdown(event, item.phone)
                            } // Use the phone as a unique key to identify which dropdown is opened
                            className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none'
                            id='options-menu'
                            aria-haspopup='true'
                            aria-expanded='true'
                          >
                            <FiMoreVertical />
                          </button>

                          {activeDropdownIndex === item.phone && (
                            <div className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white z-10'>
                              <div
                                className='py-1'
                                role='menu'
                                aria-orientation='vertical'
                                aria-labelledby='options-menu'
                              >
                                <button
                                  onClick={(event) =>
                                    openEditModal(event, item)
                                  }
                                  className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                  role='menuitem'
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={(event) =>
                                    disableTheProvider(event, item)
                                  }
                                  className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                  role='menuitem'
                                >
                                  Delete
                                </button>
                                <button
                                  onClick={(event) =>
                                    openTransferAmountModal(event, item)
                                  }
                                  className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                  role='menuitem'
                                >
                                  Amount Transfer
                                </button>{' '}
                                <button
                                  onClick={(event) =>
                                    openAmountUpdateModal(event, item)
                                  }
                                  className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                  role='menuitem'
                                >
                                  Update Amount
                                </button>{' '}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ManageProviders;
