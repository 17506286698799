import React, { useState } from 'react';
import { useUpdateUpiProviderMutation } from '../../query-services/brokerApi';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const EditProviderModal = ({ closeModal, item }) => {
  const [updateUpiProvider, data, error, isLoading, isFetching, isSuccess] =
    useUpdateUpiProviderMutation();
  const brokerAddress = Cookies.get('brokerAddress');

  const [name, setName] = useState(`${item.name}`);
  const [phone, setPhone] = useState(`${item.phone}`);
  const [password, setPassword] = useState(null);
  const [upiId, setUpiId] = useState(item.upiId);
  const [tier, setTier] = useState(item.tier);

  const finalRequestData = {
    name,
    phone,
    password,
    brokerAddress,
    upiId,
    tier,
  };

  // console.log(finalRequestData);

  const handleSubmit = async () => {
    await updateUpiProvider(finalRequestData);

    closeModal();
  };
  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>

        <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
          <div className='bg-[#202020ff] text-[#01c38e]  px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                <h3 className='text-2xl leading-6 uppercase font-semibold text-center text-[#01c38e] mb-6 '>
                  Update provider details
                </h3>
                <div className='mt-2'>
                  <label className='block font-bold '>Name</label>

                  <input
                    type='text'
                    placeholder='Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className='mt-1 mb-2  w-full px-3 py-2 border text-[#202020ff] border-gray-300 rounded-md'
                  />
                  <label className='block font-bold '>Phone Number</label>

                  <input
                    type='tel'
                    placeholder='Phone Number'
                    value={phone}
                    readOnly
                    disabled
                    // onChange={(e) => setPhone(e.target.value)}
                    className='mt-1 mb-2 w-full px-3 py-2 border cursor-not-allowed bg-slate-100 text-[#202020ff] border-gray-300 rounded-md'
                  />
                  <label className='block font-bold '>Password</label>

                  <input
                    type='text'
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className='mt-1 mb-2  w-full px-3 py-2 border text-[#202020ff] border-gray-300 rounded-md'
                  />
                  <label className='block font-bold '>UPI ID</label>

                  <input
                    type='text'
                    placeholder='UPI ID'
                    value={upiId}
                    onChange={(e) => setUpiId(e.target.value)}
                    className='mt-1 mb-2  w-full px-3 py-2 border text-[#202020ff] border-gray-300 rounded-md'
                  />
                  <label className='block font-bold '>Tier</label>

                  <select
                    className='mt-1 mb-2  w-full px-3 py-2 border text-[#202020ff] border-gray-300 rounded-md'
                    onChange={(e) => setTier(e.target.value)}
                    value={tier}
                  >
                    <option value='SILVER'>SILVER</option>
                    <option value='GOLD'>GOLD</option>
                    <option value='PLATINUM'>PLATINUM</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='bg-[#202020ff] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
            <span className='flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto'>
              <button
                onClick={handleSubmit}
                type='button'
                className='inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-[#202020ff] shadow-sm hover:text-gray-700 focus:outline-none focus:border-greem-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5'
              >
                Submit
              </button>
            </span>
            <span className='mt-2 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto'>
              <button
                onClick={closeModal}
                type='button'
                className='inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5'
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProviderModal;
