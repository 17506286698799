import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function ProtectedRoutes({ auth, allowedRoles }) {
  const brokerType = Cookies.get('brokerType');

  const isAuthorized = allowedRoles ? allowedRoles.includes(brokerType) : true;

  return auth && isAuthorized ? (
    <Outlet />
  ) : (
    (!isAuthorized ? <Navigate to='/dashboard' /> : '') || (
      <Navigate to='/login' />
    )
  );
}

export default ProtectedRoutes;
