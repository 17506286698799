import React, { useState } from 'react';
import {
  useBrokerTransactionsQuery,
  useReassignProviderMutation,
} from '../../query-services/brokerApi';
import Cookies from 'js-cookie';
import toast, { Toaster } from 'react-hot-toast';

function ReassignModal({ providers, closeModal, orderId }) {
  const brokerAddress = Cookies.get('brokerAddress');

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [reassignProvider, data, error, isLoading, isFetching, isSuccess] =
    useReassignProviderMutation();
  // console.log(selectedProvider);
  // const {
  //   data: transactionsData,
  //   error: transactionsDataError,
  //   isLoading: transactionsData,
  // } = useBrokerTransactionsQuery(brokerAddress);

  const handleSubmit = async () => {
    if (selectedProvider) {
      const finalRequest = {
        orderId: orderId, // assuming orderId is passed as a prop
        phone: selectedProvider.phone,
        brokerAddress,
      };
      await reassignProvider(finalRequest);
      toast.success('Order reassigned succesfully');

      // notify();
      closeModal();
    }
  };
  // ⛳  data status
  const notify = () => {
    if (!isLoading && data === 200) {
      toast.success('Order reassigned succesfully');
    } else if (!isLoading && data !== 200) {
      toast.error('Order reassignenment unsucessfull');
    }
  };

  // console.log(providers);
  return (
    <div className='fixed inset-0 flex items-center justify-center z-50'>
      {/* <button onClick={notify}>Make me a toast</button> */}
      <Toaster />
      <div className='bg-[#202020ff] p-5 rounded shadow-lg text-[#01c38e] w-1/2 '>
        <h2 className='text-xl font-bold mb-5 text-center '>
          Select a provider to reassign the transaction
        </h2>
        <table className='w-full table-auto'>
          <thead>
            <tr className='text-white '>
              <th className='px-4 py-2 uppercase font-medium'>Provider Name</th>
              <th className='px-4 py-2 uppercase font-medium'>
                Provider upi Id
              </th>
            </tr>
          </thead>
          <tbody>
            {providers.map((provider, index) => (
              <tr
                key={index}
                className={`cursor-pointer text-[#01c38e] ${
                  selectedProvider === provider ? 'bg-[#01c38e] text-black' : ''
                }`}
                onClick={() => setSelectedProvider(provider)}
              >
                <td className='border px-4 py-2'>{provider.phone}</td>
                <td className='border px-4 py-2'>{provider.upiId}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='button-container flex justify-end w-full mt-5 gap-4'>
          <button
            onClick={handleSubmit}
            className={` p-2 bg-[#01c38e] text-white rounded ${
              !selectedProvider
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-[#01c38e]'
            }`}
            disabled={!selectedProvider}
          >
            Submit
          </button>
          <button
            onClick={closeModal}
            className='p-2 bg-white text-red-600 rounded hover:bg-red-100 '
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReassignModal;
