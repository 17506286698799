import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

// const tokenExpiry = localStorage.getItem('tokenExpiry');

export const brokerApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_APP_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = Cookies.get('jwtToken');
      // if (new Date().getTime() > tokenExpiry) {
      //   // Token has expired
      //   localStorage.removeItem('token');
      //   localStorage.removeItem('tokenExpiry');
      //   // Here you can also redirect the user to login page, or refresh the token if your backend supports this operation
      // }
      headers.set('Authorization', `JWT ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    brokerAuthentication: builder.mutation({
      query: (id) => ({
        url: `/broker/auth`,
        method: 'POST',
        body: { key: id },
      }),
    }),
    brokerTransactions: builder.query({
      query: (brokerAddress) => ({
        url: `/broker-transactions?brokerAddress=${brokerAddress}`,
      }),
      pollingInterval: 60000,
      providesTags: [{ type: 'Transactions' }],
    }),
    createUpiProvider: builder.mutation({
      query: (upiProviderData) => ({
        url: `/upi-provider`,
        method: 'POST',
        body: upiProviderData,
      }),
      invalidatesTags: [{ type: 'UpiProvider' }],
    }),
    updateUpiProvider: builder.mutation({
      query: (upiProviderData) => ({
        url: `/upi-provider`,
        method: 'PATCH',
        body: upiProviderData,
      }),
      invalidatesTags: [{ type: 'UpiProvider' }],
    }),
    getUpiProvider: builder.query({
      query: (brokerAddress) => ({
        url: `/upi-providers?brokerAddress=${brokerAddress}`,
      }),
      pollingInterval: 300000,

      providesTags: [{ type: 'UpiProvider' }],
    }),
    getWalletBalance: builder.query({
      query: (brokerAddress) => ({
        url: `/wallet-balances?brokerAddress=${brokerAddress}`,
      }),
      pollingInterval: 60000,
      invalidatesTags: [{ type: 'Transactions' }],

      // providesTags: [{ type: 'UpiProvider' }],
    }),

    reassignProvider: builder.mutation({
      query: (providerData) => ({
        url: `/reassign-provider`,
        method: 'POST',
        body: providerData,
      }),
      // invalidatesTags: [{ type: 'Transactions' }],
    }),
    disableProvider: builder.mutation({
      query: (disableProviderData) => ({
        url: `/disable-provider`,
        method: 'POST',
        body: disableProviderData,
      }),
      invalidatesTags: [{ type: 'UpiProvider' }],
    }),
    enableProvider: builder.mutation({
      query: (enableProviderData) => ({
        url: `/enable-provider`,
        method: 'POST',
        body: enableProviderData,
      }),
      invalidatesTags: [{ type: 'UpiProvider' }],
    }),
    archiveTransaction: builder.mutation({
      query: (transactionData) => ({
        url: `/archive-transaction`,
        method: 'PATCH',
        body: transactionData,
      }),
      invalidatesTags: [{ type: 'Transactions' }],
    }),
    restoreTransaction: builder.mutation({
      query: (transactionData) => ({
        url: `/restore-transaction`,
        method: 'PATCH',
        body: transactionData,
      }),
      invalidatesTags: [{ type: 'Transactions' }],
    }),
    updateRp: builder.mutation({
      query: (rpData) => ({
        url: `/broker/update-rp`,
        method: 'POST',
        body: rpData,
      }),
      invalidatesTags: [{ type: 'Transactions' }],
    }),
    transferReferralReward: builder.mutation({
      query: (rewardData) => ({
        url: `/broker/send-referral-reward`,
        method: 'POST',
        body: rewardData,
      }),
    }),
    amountTransfer: builder.mutation({
      query: (transferData) => ({
        url: `/broker/provider-amount-transfer`,
        method: 'POST',
        body: transferData,
      }),
      invalidatesTags: [{ type: 'UpiProvider' }],
    }),
    amountUpdate: builder.mutation({
      query: (updateData) => ({
        url: `/broker/provider-amount-transfer`,
        method: 'POST',
        body: updateData,
      }),
      invalidatesTags: [{ type: 'UpiProvider' }],
    }),
    buySellStatus: builder.mutation({
      query: (status) => ({
        url: `/upi-provider/status`,
        method: 'POST',
        body: status,
      }),
      invalidatesTags: [{ type: 'UpiProvider' }],
    }),
    reconciliationData: builder.query({
      query: (brokerAddress) => ({
        url: `/broker/reconciliation-data?brokerAddress=${brokerAddress}`,
      }),
    }),
    priceHandling: builder.mutation({
      query: (pricingType) => ({
        url: `/super-admin/price-discovery/settings`,
        method: 'POST',
        body: pricingType,
      }),
      invalidatesTags: [{ type: 'Pricing' }],
    }),
    getPriceDiscovery: builder.query({
      query: (brokerAddress) => ({
        url: `/super-admin/price-discovery/settings?brokerAddress=${brokerAddress}`,
      }),
      providesTags: [{ type: 'Pricing' }],
    }),
    getVoterAddress: builder.query({
      query: ({ brokerAddress, userAddress }) => ({
        url: `/broker/voter-address?brokerAddress=${brokerAddress}&userAddress=${userAddress}`,
      }),
    }),
    getRecommenderAddresses: builder.query({
      query: ({ brokerAddress, recommenderAddress }) => ({
        url: `/broker/get-recommendations?brokerAddress=${brokerAddress}&recommenderAddress=${recommenderAddress}`,
      }),
    }),
    getBinancePrices: builder.query({
      query: (brokerAddress) => ({
        url: `/broker/binance-estimated-prices?brokerAddress=${brokerAddress}`,
      }),
    }),
    getAccountingData: builder.query({
      query: (brokerAddress) => ({
        url: `/broker/accounting?brokerAddress=${brokerAddress}`,
      }),
    }),
  }),
});
export const {
  useBrokerAuthenticationMutation,
  useBrokerTransactionsQuery,
  useCreateUpiProviderMutation,
  useUpdateUpiProviderMutation,
  useEnableProviderMutation,
  useDisableProviderMutation,
  useReassignProviderMutation,
  useArchiveTransactionMutation,
  useRestoreTransactionMutation,
  useGetUpiProviderQuery,
  useGetWalletBalanceQuery,
  useUpdateRpMutation,
  useTransferReferralRewardMutation,
  useAmountTransferMutation,
  useAmountUpdateMutation,
  useBuySellStatusMutation,
  useReconciliationDataQuery,
  usePriceHandlingMutation,
  useGetPriceDiscoveryQuery,
  useGetVoterAddressQuery,
  useGetBinancePricesQuery,
  useGetRecommenderAddressesQuery,
  useGetAccountingDataQuery,
} = brokerApi;
