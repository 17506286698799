import React from 'react';

function SalesReport() {
  return (
    <div className="sales-report-container w-full rounded-md p-4  border-[1px] border-[#EAECF0] mt-4 flex  flex-col items-start ">
      <div className="top-container w-full flex justify-between">
        <div className="sales-report-text text-lg ">Sales report</div>
        <button className="view-report-button  border-[1px] border-[#D0D5DD] px-2 py-1 rounded-md">
          view report
        </button>
      </div>
      <div className="timeline-container w-max">
        <ul className="timeline-list flex gap-4 justify-start">
          <li className="px-2 py-1 bg-[#F9FAFB] text-[#344054] rounded-md">
            12 months
          </li>{' '}
          <li className="px-2 py-1  text-[#344054] rounded-md">3 months</li>
          <li className="px-2 py-1  text-[#344054] rounded-md">30 days</li>
          <li className="px-2 py-1  text-[#344054] rounded-md"> 7days</li>
          <li className="px-2 py-1  text-[#344054] rounded-md">24 hours</li>
        </ul>
      </div>
      <div className="graph-container h-48 bg-sky-200 w-full mt-4 flex justify-center items-center">
        Chart will be placed here
      </div>
    </div>
  );
}

export default SalesReport;
