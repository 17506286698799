import { configureStore } from '@reduxjs/toolkit';
import uiStateReducer from './uiState';
import { brokerApi } from '../query-services/brokerApi';
import { upiProviderApi } from '../query-services/upiProviderApi';

export default configureStore({
  reducer: {
    uiState: uiStateReducer,
    [brokerApi.reducerPath]: brokerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(brokerApi.middleware),
});
