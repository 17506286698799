import React, { useMemo, useState } from 'react';
import { useAmountTransferMutation } from '../../query-services/brokerApi';

import Cookies from 'js-cookie';
function AmountTransferModal({
  closeModal,
  item,
  amount,
  type,
  providersData,
}) {
  const brokerAddress = Cookies.get('brokerAddress');
  const [amountTransfer, data, error, isLoading, isFetching, isSuccess] =
    useAmountTransferMutation();
  const [toPhone, setToPhone] = useState('');
  const [fromPhone, setFromPhone] = useState(item.phone);
  const [updatedAmount, setUpdatedAmount] = useState(null);
  const [transferAmount, setTransferAmount] = useState(null);
  const [updatedAmountForPhone, setUpdatedAmountForPhone] = useState(
    item.phone
  );
  console.log(amount);
  console.log('providersData', providersData);
  const filteredProviders = useMemo(() => {
    return providersData.filter(
      (provider) => provider.phone !== item.phone && provider.deletedAt === null
    );
  }, [providersData, item.phone]);
  const filteredProviderAmount = useMemo(() => {
    return providersData.filter((provider) => provider.phone === item.phone);
  }, [providersData, item.phone]);
  console.log('filteredProviderAmount', filteredProviderAmount);
  const amountTransferHandleRequest = async () => {
    const finalRequestData = {
      fromPhone,
      toPhone,
      amount: parseFloat(transferAmount),
      brokerAddress,
    };
    await amountTransfer(finalRequestData);

    closeModal();
  };
  const amountUpdateHandleRequest = async () => {
    const finalRequestData = {
      toPhone: updatedAmountForPhone,
      amount: parseFloat(updatedAmount),
      brokerAddress,
    };
    await amountTransfer(finalRequestData);

    closeModal();
  };
  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        {type === 'AmountTransfer' ? (
          <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
            <div className='bg-[#202020ff] text-[#01c38e]  px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <h3 className='text-2xl leading-6 uppercase font-semibold text-center text-[#01c38e] mb-6 '>
                    Amount Transfer
                  </h3>{' '}
                  <h3 className='text-xl leading-6 uppercase font-semibold text-left text-[#fff] mb-6 '>
                    Current Balance:
                    {' ' + filteredProviderAmount[0]?.amount + ' INR'}
                  </h3>
                  <div className='mt-2'>
                    <label className='block font-bold '>
                      From ({item.name})
                    </label>

                    <input
                      type='tel'
                      value={item.phone}
                      readOnly
                      // onChange={(e) => setName(e.target.value)}
                      className='mt-1 mb-2  w-full px-3 py-2 border  cursor-not-allowed text-[#202020ff] border-gray-300 rounded-md'
                    />
                    <label className='block font-bold '>To</label>

                    <select
                      value={toPhone}
                      onChange={(e) => setToPhone(e.target.value)}
                      className='mt-1 mb-2 w-full px-3 py-2 border bg-slate-100 text-[#202020ff] border-gray-300 rounded-md'
                    >
                      <option value=''>Select a phone number</option>
                      {filteredProviders.map((provider, index) => (
                        <option key={index} value={provider.phone}>
                          {`(${provider.name})  ${provider.phone}`}
                        </option>
                      ))}
                    </select>
                    <label className='block font-bold '>Enter Amount </label>

                    <input
                      type='number'
                      placeholder='Amount to be transferred'
                      value={transferAmount}
                      onChange={(e) => setTransferAmount(e.target.value)}
                      className='mt-1 mb-2  w-full px-3 py-2 border text-[#202020ff] border-gray-300 rounded-md'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='bg-[#202020ff] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
              <span className='flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto'>
                <button
                  onClick={amountTransferHandleRequest}
                  type='button'
                  className='inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-[#202020ff] shadow-sm hover:bg-[#01c38faf] focus:outline-none focus:border-greem-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                >
                  Submit
                </button>
              </span>
              <span className='mt-2 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto'>
                <button
                  onClick={closeModal}
                  type='button'
                  className='inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        ) : (
          <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
            <div className='bg-[#202020ff] text-[#01c38e]  px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <h3 className='text-2xl leading-6 uppercase font-semibold text-center text-[#01c38e] mb-6 '>
                    Update Amount
                  </h3>

                  <h3 className='text-xl leading-6 uppercase font-semibold text-left text-[#fff] mb-6 '>
                    Current Balance:
                    {' ' + filteredProviderAmount[0]?.amount + ' INR'}
                  </h3>
                  <div className='mt-2'>
                    <label className='block font-bold '>
                      Update for ({item.name})
                    </label>

                    <input
                      type='tel'
                      value={item.phone}
                      readOnly
                      // onChange={(e) => setName(e.target.value)}
                      className='mt-1 mb-2  w-full px-3 py-2 border  cursor-not-allowed text-[#202020ff] border-gray-300 rounded-md'
                    />
                    {/* <label className='block font-bold '>To</label> */}

                    {/* <input
                      type='number'
                      placeholder='Phone Number'
                      onChange={(e) => setToPhone(e.target.value)}
                      className='mt-1 mb-2 w-full px-3 py-2 border bg-slate-100 text-[#202020ff] border-gray-300 rounded-md'
                    /> */}
                    <label className='block font-bold '>Enter Amount </label>

                    <input
                      type='number'
                      placeholder='Amount to be updated'
                      value={updatedAmount}
                      onChange={(e) => setUpdatedAmount(e.target.value)}
                      className='mt-1 mb-2  w-full px-3 py-2 border text-[#202020ff] border-gray-300 rounded-md'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='bg-[#202020ff] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
              <span className='flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto'>
                <button
                  onClick={amountUpdateHandleRequest}
                  type='button'
                  className='inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-[#01c38e] text-base leading-6 font-medium text-[#202020ff] shadow-sm hover:text-gray-700 focus:outline-none focus:border-greem-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                >
                  Submit
                </button>
              </span>
              <span className='mt-2 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto'>
                <button
                  onClick={closeModal}
                  type='button'
                  className='inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AmountTransferModal;
